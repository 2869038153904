import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  handleMissingFrame(event) {

    console.log("Handling missing frame: ", event);

    const responseURL = event.detail.fetchResponse.response.url;

    if (event.detail.fetchResponse.redirected) {
      // Perform a full-page navigation to break out of the Turbo Frame
      Turbo.visit(responseURL);
    }
  }

}
