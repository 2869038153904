import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  handleSubmitEnd(event) {

    console.log("Handling form submission: ", event);

    const { detail } = event;
    const { fetchResponse } = detail;

    if (!fetchResponse) return; // No response to handle

    if (fetchResponse.redirected) {
      console.log("Redirecting")
      // If the response is a redirect, perform a full-page visit
      Turbo.visit(fetchResponse.response.url);
      // await this.visitResponse(fetchResponse);
    }
    // Otherwise, let Turbo handle the response within the frame
  }

  // async visitResponse(response) {
  //   const wrapped = new FetchResponse(response);
  //   const responseHTML = await wrapped.responseHTML;
  //   const { location, redirected, statusCode } = wrapped;

  //   return Turbo.session.visit(location, { response: { redirected, statusCode, responseHTML } });
  // }


}
