import { Controller } from "@hotwired/stimulus";

// Emulate <a> tag behaviour to allow nesting <a> tags
// and making stuff like <div> act just like a link
// e.g. make a progress bar act like a link

// Ideally emulate turbo pre-loading on hover
export default class extends Controller {
  static values = { url: String };

  connect() {
    // console.log(this.urlValue);
  }

  navigate(event) {
    // event.target == this.element
    // Ignore events propagated up from <a> tags
    if (event.target.nodeName !== "A") {
      console.log("[LinkController] Navigating to: ", this.urlValue);
      Turbo.visit(this.urlValue)
    }
  }
  
}
