import { patch } from "@rails/request.js";
import Sortable from '@stimulus-components/sortable';

export default class extends Sortable {

  async onUpdate({ item, newIndex, oldIndex }) {
    if (!item.dataset.sortableUpdateUrl) return

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue;
    const param2 = this.resourceNameValue ? `${this.resourceNameValue}[${this.relParamName}]` : this.relParamName;
    const param3 = this.resourceNameValue ? `${this.resourceNameValue}[${this.adjacentIdParamName}]` : this.adjacentIdParamName;


    // Get sortable children of current row, then use index to find adjacent element

    const adjacentElement = this.element.querySelectorAll("th")[newIndex - 1];
    console.log(adjacentElement);
    const adjacentElementId = adjacentElement.dataset["id"];


    const data = new FormData();
    data.append(param, newIndex + 1);
    data.append(param2, newIndex - oldIndex);
    data.append(param3, adjacentElementId);

    return await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
  }

  get relParamName() {
    return "position_change";
  }

  get adjacentIdParamName() {
    return "adjacent_id"
  }

}
