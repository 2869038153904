import { ApplicationController, useDebounce } from "stimulus-use";

export default class extends ApplicationController {
  static debounces = [ "click" ];
  static targets = [ "click" ];

  connect() {
    useDebounce(this, { wait: 150 });
  }

  click() {
    this.clickTargets.forEach(target => target.click());
  }
}
