import { Controller } from "@hotwired/stimulus";
import SlimSelect from 'slim-select';

import 'slim-select/styles';

export default class extends Controller {
  static targets = ['instance'];

  static values = {
    limit: Number,
    placeholder: String,
    searchText: String,
    searchingText: String,
    reflex: String,
    showSearch: Boolean
  };

  connect() {
    console.log("[SlimController] -- CONNECT --");

    this.create();

    this.reconnect = this.reconnect.bind(this); // Ensure that `this` points back to our controller
    window.addEventListener('turbo:morph', this.reconnect);
  }

  get single() {
    return !this.selectElement.multiple;
  }
  get multi() {
    return this.selectElement.multiple;
  }

  get selectElement() {
    return (this.hasInstanceTarget && this.instanceTarget) || this.element
  }

  create() {
    const closeOnSelect = this.single;
    const allowDeselect = !this.selectElement.required;

    // Set automatically based on the number of options?
    if (this.hasShowSearchValue) {
      console.log("[SlimController] showSearch: ", this.showSearchValue);
    }

    // Cache cross site?
    // What does it mean to say that a leaver is cross-site? There would be de-normalisation again with the idea
    // of being on-roll

    // Need to fix the idempotency of this

    // if (this.select) {
    //   // We are re-connecting, ensure destroyed
    //   this.destroy();
    // }

    this.select = new SlimSelect({
      select: this.selectElement,
      settings: {
        closeOnSelect: closeOnSelect,
        allowDeselect: allowDeselect,
        limit: this.limitValue,
        showSearch: this.hasShowSearchValue ? this.showSearchValue : true,
        placeholderText: this.hasPlaceholderValue ? this.placeholderValue : 'Select Value',
        searchText: this.hasSearchTextValue ? this.searchTextValue : 'No Results',
        searchingText: this.hasSearchingTextValue ? this.searchingTextValue : 'Searching...',
      }
      // ajax: this.hasReflexValue ? this.search : () => {},
      // onChange: this.onChange
    });
  }
  
  // Method for cleaning up the DOM before Turbo Drive caches the page
  // TODO: somehow detect if this came from a turbo frame promoted to a navigation??
  teardown() {
    console.log("[SlimController] Destroying Slim in teardown.");
    this.destroySlim();
  }

  destroySlim() {
    this.select.destroy();
  }



  disconnect() {
    console.log("[SlimController] -- DISCONNECT --");
    // this.select.destroy();
    // if (this.hasReflexValue) document.removeEventListener('data', this.results)
    window.removeEventListener('turbo:morph', this.reconnect);
  }

  reconnect = event => {
    console.log("[SlimController] Reconnect (likely morph)");
    this.destroySlim();
    this.create();
   // Or re-initialize your library, etc.  
  }



}
