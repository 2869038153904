// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr';

import predefinedRangesPlugin from "../flatpickr/predefined_ranges";

import {
  addDays,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subDays
} from "date-fns";

// you can also import a translation file
// import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

  static values = { ranges: Object };

  initialize() {
    // Need to guard against not being a range picker?
    this.config = {
      plugins: [new predefinedRangesPlugin()],
      ranges: {
          "Today": [startOfToday(), startOfTomorrow()],
          "Last 7 days": [subDays(startOfToday(), 7), startOfTomorrow()],
          "Last 14 days": [subDays(startOfToday(), 14), startOfTomorrow()],
          "This Week": [startOfWeek(new Date()), startOfTomorrow()],
          "This Month": [startOfMonth(new Date()), startOfTomorrow()]
          // 'Last 30 Days': [moment().subtract(29, 'days').toDate(), new Date()],
          // 'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          // 'Last Month': [
          //     moment().subtract(1, 'month').startOf('month').toDate(),
          //     moment().subtract(1, 'month').endOf('month').toDate()
          // ]
      },
      rangesOnly: false, // only show the ranges menu unless the custom range button is selected
      rangesAllowCustom: true, // adds a Custom Range button to show the calendar
      rangesCustomLabel: 'Custom Range' // customize the label for the custom range button
    }
    this.setAdditionalRanges();

    this.reconnect = this.reconnect.bind(this); // Ensure that `this` points back to our controller
    window.addEventListener('turbo:morph', this.reconnect);
  }

  connect() {
    super.connect();
    // Fix for Turbo drive caching
    this.calendarContainerTarget.dataset.turboTemporary = true;
  }

  // Combine ranges passed as attribute
  setAdditionalRanges() {
    if (this.hasRangesValue) {
      this.config.ranges = { ...this.config.ranges, ...this.rangesValue };
    }
  }

  clear() {
    this.fp.clear();
  }

  reconnect = event => {
    console.log("[Flatpickr] Reconnect.");
    this.connect();
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log('the callback returns the selected dates', selectedDates)
  //   console.log('but returns it also as a string', dateStr)
  //   console.log('and the flatpickr instance', instance)
  // }
}
