// console.log('Vite ⚡️ Rails');

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>


import "@hotwired/turbo-rails";

// import ActiveStorage from '@rails/activestorage';
// ActiveStorage.start();

// Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
import.meta.glob('../channels/**/*_channel.ts', { eager: true });

// import '../utils/setupServiceWorker.ts';
import '../utils/setupStimulus.ts';
import '../utils/setupViewTransitions.ts';

// import jQuery from "jquery";
// globalThis.jQuery = jQuery;
// window.jQuery = jQuery;


// Importing CSS from JS with Vite!
// import 'fomantic-ui/dist/semantic.min.css';

// Import with side-effects - no CommonJS or ESM
import 'fomantic-ui/dist/semantic.min.js';

import $ from 'jquery';

$(document).on('turbo:load', function() {
    console.log('Loaded turbo links');
});

import '../utils/tablesort.js';

// $.fn.search.settings.debug = true;
// console.table($.fn.search.settings);


// import { Idiomorph } from "idiomorph/dist/idiomorph.esm.js";


// console.log(Idiomorph);


// addEventListener("turbo:before-frame-render", ({ detail }) => {
//   detail.render = (prevEl, newEl) => {
//     console.log("Before render frame");
//     Idiomorph.morph(prevEl, newEl.children, { morphStyle: "innerHTML" });
//   };
// }, { once: false });



// let containerScrollTops = {};

// addEventListener("turbo:click", () => {
//   document
//     .querySelectorAll("[data-turbo-preserve-scroll-container]")
//     .forEach((ele) => {
//       containerScrollTops[ele.dataset.turboPreserveScrollContainer] =
//         ele.scrollTop;
//         console.log("Scoll test: ", ele.scrollTop);
//         console.log(containerScrollTops);
//     });
// });

// addEventListener("turbo:load", () => {
//   document
//     .querySelectorAll("[data-turbo-preserve-scroll-container]")
//     .forEach((ele) => {
//       const containerScrollTop =
//         containerScrollTops[ele.dataset.turboPreserveScrollContainer];
//       if (containerScrollTop) ele.scrollTo(0, containerScrollTop);
//       console.log(containerScrollTops);
//     });

//   containerScrollTops = {};
// });
