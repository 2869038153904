import { Controller } from "@hotwired/stimulus";
import { get, post, destroy } from "@rails/request.js";
import $ from "jquery";

// TODO:
/*

support selecting ranges with SHIFT, and multiple ranges
support copying ranges

scroll selected cell into view

show column and row as selected

ctrl+z for undo (delete last submitted score)

support pasting values

*/

export default class extends Controller {

  static values = {
    row: { type: Number, default: 1 },
    col: { type: Number, default: 1 },
    editing: { type: Boolean, default: false }
  };

  static targets = [ "submittedScoreTemplate" ];

  initialize() {
    this.selectedCell = null;
    this.selectedAttribute = "data-selected"; // Define the attribute for selected state
    this.table = this.element;
    // this.editingCol = null;
  }

  connect() {
    $(this.element).tablesort();
  }

  rowValueChanged() {
    this.updateSelection();
  }

  colValueChanged() {
    this.updateSelection();
  }

  updateSelection() {
    const cell = this.getCell(this.rowValue, this.colValue);
    const { col } = cell.dataset;

    // Select row and column
    // this.table.rows.each()
    if (this.tableRow) {
      for(const row of this.table.rows) {
        row.removeAttribute(this.selectedAttribute);
      }
      this.tableRow.setAttribute(this.selectedAttribute, "true");
    }

    const headerCells = this.table.querySelectorAll(`th[data-col="${col}"]`);

    for (const h of this.table.querySelectorAll("th")) {
      h.removeAttribute(this.selectedAttribute);
    }
    for (const h of headerCells) {
      h.setAttribute(this.selectedAttribute, "true");
    }

    if (cell && cell != this.selectedCell) {
      this.selectCell(cell);
    }
  }

  get tableRow() {
    return this.table.rows[this.rowValue];
  }

  getHeaderCells(colIndex) {
    const headers = [];
    let currentIndex = 0;

    // Iterate through all rows in the table's <thead>
    const headerRows = this.table.tHead?.rows || [];
    for (const row of headerRows) {
      for (const cell of row.cells) {
        const colspan = parseInt(cell.colSpan, 10) || 1;

        // Check if the column index falls within this cell's colspan range
        if (colIndex >= currentIndex && colIndex < currentIndex + colspan) {
          headers.push(cell);
          break; // Move to the next row after finding the relevant header
        }

        currentIndex += colspan; // Increment the index for the next cell
      }
      currentIndex = 0; // Reset index for the next row
    }

    return headers;
  }



  // Handle keydown events
  keydown(event) {
    if (!this.selectedCell) return;

    switch (event.key) {
      case "ArrowUp":
        // event.preventDefault();
        this.moveSelection(-1, 0);
        break;
      case "ArrowDown":
        // event.preventDefault();
        this.moveSelection(1, 0);
        break;
      case "ArrowLeft":
        // event.preventDefault();
        this.moveSelection(0, -1);
        break;
      case "ArrowRight":
        // event.preventDefault();
        this.moveSelection(0, 1);
        break;
      case "e":
        // event.preventDefault();
        this.toggleEditing();
        break;
    }

    // This approach would need to be very low-latency
    // if (event.key !== " " && isFinite(event.key)) {
    //   this.edit(event)
    // }

    console.log("Key: ", event.key);

    if (event.key == "Delete") {
      this.delete(event);
    }

    // The alternative is to just insert a form / input field dynamically,
    // possibly using a template
  }

  toggleEditing() {
    this.editingValue = !this.editingValue;
  }

  async delete(event) {
    this.clearSelection();
    const { url } = this.selectedCell.dataset;
    await destroy(url + window.location.search, { responseKind: "turbo-stream" });
  }

  
  async edit() {
    // this.clearSelection();
    // await this.requestEdit({ "marksheet_edit": "true" });
    // this.focusInput(this.selectedCell);
  }

  clearSelection() {
    if (window.getSelection) {
      window.getSelection().removeAllRanges()
    } else if (document.selection) {
      document.selection.empty()
    }
  }


  click(event) {
    if (event.target.nodeName === "TD" || event.target.nodeName === "SPAN") {
      const cell = event.target.closest("td");
      this.rowValue = cell.parentElement.rowIndex;
      this.colValue = cell.cellIndex;

      // Need this in case the row has been replaced from turbo stream
      this.selectCell(cell);

      // this.selectCell(event.target);
    }
  }

  // moveSelection(rowDelta, colDelta) {
  //   const currentRow = this.selectedCell.parentElement.rowIndex;
  //   const currentCol = this.selectedCell.cellIndex;

  //   const newRow = currentRow + rowDelta;
  //   const newCol = currentCol + colDelta;

  //   const newCell = this.getCell(newRow, newCol);
  //   if (newCell) {
  //     this.selectCell(newCell);
  //   }
  // }

  moveSelection(rowDelta, colDelta) {
    this.rowValue += rowDelta;
    this.colValue += colDelta;
  }



  getCell(row, col) {
    const rows = Array.from(this.table.rows);
    if (row >= 0 && row < rows.length) {
      const cells = Array.from(rows[row].cells);
      if (col >= 0 && col < cells.length) {
        return cells[col];
      }
    }
    return null;
  }

  // An editing mode for a column?
  editCol(col) {

  }

  selectCell(cell) {
    if (this.selectedCell) {
      this.deselectCell(this.selectedCell);
      // this.selectedCell.removeAttribute(this.selectedAttribute);
    }

    this.selectedCell = cell;
    this.selectedCell.setAttribute(this.selectedAttribute, "true");
    // this.selectedCell.setAttribute("data-marksheet-cell-selected-value", "true");


    // New approach - insert the form client-side when the cell is selected
    const { assignmentId, studentId } = this.selectedCell.dataset;
    if (assignmentId && studentId && this.editingValue) {
      this.insertSubmittedScoreForm();
    }


    // Non-standard thing that might break, but it works well enough for now
    this.selectedCell.scrollIntoViewIfNeeded(false);

    const form = this.selectedCell.querySelector("form");
    const span = this.selectedCell.querySelector("span");
    if (form) {
      form.hidden = false;
      span.hidden = true;
      const input = form.querySelector("input:not([type='hidden'])");
      input.focus();
      input.select();
    }

    if (this.selectedCell.dataset['editUrl']) {
      // this.edit();
    }



  }

  insertSubmittedScoreForm() {
    let template = this.submittedScoreTemplateTarget.content.cloneNode(true);
    
    const studentInput = template.querySelector("#submitted_score_student_id");
    const assignmentInput = template.querySelector("#submitted_score_assignment_id");
    const valueInput = template.querySelector("#submitted_score_value");

    const { assignmentId, studentId } = this.selectedCell.dataset;

    if (assignmentInput) assignmentInput.value = assignmentId;
    if (studentInput) studentInput.value = studentId;

    this.selectedCell.appendChild(template);
  }


  async submitForm(form) {
    const formData = new FormData(form);
    // Add in marksheet_edit field for turbo_stream?

    try {
      const response = await post(form.action, {
        body: formData,
        responseKind: "turbo-stream"
      });

      if (response.ok) {
        console.log("Form submitted successfully");
      } else {
        console.error("Form submission failed", await response.text());
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }


  deselectCell(cell) {
    cell.removeAttribute(this.selectedAttribute);
    const form = cell.querySelector("form");
    const span = cell.querySelector("span");

    // Submit form on deselect if the input has changed
    if (form) {
      console.log("Might submit form: ", form);
      const input = form.querySelector("input:not([type='hidden'])");
      if (input && input.value && form.dataset["changed"]) {
        // form.requestSubmit();
        this.submitForm(form);
        form.removeAttribute("changed");
      }

      form.hidden = true;
      // New approach - delete form
      if (this.constructor.isAssignmentCell(cell)) {
        form.remove();
      }

      span.hidden = false;
    }
  }

  focusInput(cell) {
    const input = cell.querySelector("input:not([type='hidden'])");
    if (input) {
      input.select();
    }
  }

  markCellAsChanged(event) {
    const input = event.target;
    const form = input.form;
    form.setAttribute("data-changed", "true");
  }

  markCellAsNotChanged(event) {
    const input = event.target;
    const form = input.form;
    form.removeAttribute("data-changed");
  }


  static isAssignmentCell(cell) {
    const { assignmentId, studentId } = cell.dataset;
    return assignmentId && studentId;
  }

}
