import { ApplicationController } from "stimulus-use";

export default class extends ApplicationController {

  static targets = [ "element" ];

  // On mouseover
  addHoverClass(event) {
    let groupTag = event.currentTarget.dataset.classGroupId;
    if (!groupTag) return;
    let matchingTargets = this.#getMatchingTargets(groupTag);
    matchingTargets.forEach(target => target.classList.add("hover"));
  }

  // On mouseleave
  removeHoverClass(event) {
    let groupTag = event.currentTarget.dataset.classGroupId;
    if (!groupTag) return;
    let matchingTargets = this.#getMatchingTargets(groupTag);
    matchingTargets.forEach(target => target.classList.remove("hover"));
  }


  #getMatchingTargets(groupTag) {
    return this.elementTargets.filter(target => target.dataset.classGroupId == groupTag);
  }






}
