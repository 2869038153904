import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { debounce } from '/utils/utils.ts';

export default class extends Controller {

  static targets = [ "dropdown" ];

  connect() {
    this.create();
    this.reconnect = this.reconnect.bind(this);
    window.addEventListener('turbo:morph', this.reconnect);
  }

  create() {
    // Fomantic-UI dropdowns can have a corresponding hidden input.
    // Leverage targets or Fomantic-UI settings? Or just re-write entire Fomantic-UI jQuery API to Stimulus? :)
    const inputElement = this.hiddenInputElement;

    if (inputElement === undefined) {
      $(this.dropdownElement).dropdown();
      return
    }

    const form = inputElement.form;

    const submitForm = debounce(() => { form.requestSubmit() }, 150);

    // TODO: Change this approach to simply create a change event that can be bubbled up
    // and trigger a form submission (auto-submit#submit)
    $(this.dropdownElement).dropdown({
      onChange: (text, value, element) => {
        if (element && element.attr("href") !== undefined) {
          console.log("Node is a link");
        } else {
          console.log("Submitting form");
          submitForm();
        }
      }
    });
  }

  clear() {
    $(this.dropdownElement).dropdown("clear");
  }

  destroyDropdown() {
    $(this.dropdownElement).dropdown("destroy");
  }

  reconnect = event => {
    this.destroyDropdown();
    this.create();  
  }

  get dropdownElement() {
    return (this.hasDropdownTarget && this.dropdownTarget) || this.element
  }

  get hiddenInputElement() {
    return $(this.dropdownElement).find($.fn.dropdown.settings.selector.input)[0];
  }
  
}
