import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "field" ];

  select(event) {
    console.log(event.currentTarget);
    console.log("Target: ", this.fieldTarget);
    this.fieldTarget.value = event.currentTarget.dataset["value"];
    this.fieldTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }));
  }
  
}
