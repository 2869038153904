import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  // static values = { selected: { type: Boolean, default: false }};

  static targets = [ "form", "input" ];

  connect() {
    
  }


  selectedValueChanged() {
    // console.log("Selected changed", this.selectedValue);
  }


  // Called when selecting this cell
  select() {
    
  }

  edit() {
    this.formTarget.hidden = false;
    this.inputTarget.focus();
    this.inputTarget.select();
  }

  finishEditing() {
    this.formTarget.hidden = true;
  }

  markAsChanged() {
    console.log("Changed");
    this.formTarget.setAttribute("data-changed", "true");
  }

  markAsNotChanged() {
    this.formTarget.removeAttribute("data-changed");
  }


  
}
