import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

import Timeago from '@stimulus-components/timeago';
import AnimatedNumber from '@stimulus-components/animated-number';
import Notification from '@stimulus-components/notification';
import AutoSubmit from '@stimulus-components/auto-submit';
import TextareaAutogrow from 'stimulus-textarea-autogrow';
// import Popover from '@stimulus-components/popover';
// import Dropdown from '@stimulus-components/dropdown';
import Dialog from '@stimulus-components/dialog';
// import Sortable from '@stimulus-components/sortable';
import Chartjs from '@stimulus-components/chartjs';
import RailsNestedForm from '@stimulus-components/rails-nested-form';
import Confirmation from '@stimulus-components/confirmation';

// Start Stimulus application
const application = Application.start();

// Load and register global controllers
registerControllers(
  application,
  import.meta.glob('../controllers/*_controller.{ts,js}', { eager: true }),
);


// Load and register view_component controllers
// registerControllers(
//   application,
//   import.meta.glob('../../components/**/*_controller.{ts,js}', { eager: true }),
// );


// Register external Stimulus components
application.register('timeago', Timeago);
application.register('animated-number', AnimatedNumber);
application.register('notification', Notification);
application.register('auto-submit', AutoSubmit);
application.register('textarea-autogrow', TextareaAutogrow);
// application.register('popover', Popover);
// Not needed since we extend the base flatpickr controller
// application.register('flatpickr', Flatpickr)
application.register('dialog', Dialog);
// application.register('sortable', Sortable);
application.register('chartjs', Chartjs);
application.register('nested-form', RailsNestedForm);
application.register('confirmation', Confirmation);

import ColorPicker from '@stimulus-components/color-picker';
import '@simonwep/pickr/dist/themes/classic.min.css';
application.register('color-picker', ColorPicker);

// Add our own custom lifecycle event, "teardown", for cleaning up the DOM
// before "disconnect".

document.addEventListener('turbo:before-cache', function() {
  application.controllers.forEach(function(controller){
    if(typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});
